import React, { useEffect, useRef, useState } from "react";
import Vector from "../assets/images/vector-img.jpeg";
import {
  fetchCategory,
  fetchCountry,
  fetchFilterData,
  fetchGrape,
  fetchHomeSubCategory,
  fetchProductSearchist,
} from "../api/fetchApi";
import { fetchHomeSubSubCategory } from "../api/fetchApi";
import { Helmet } from "react-helmet";

const Product = ({ search, onChange }) => {
  const [productList, setProductList] = useState([]);
  const [catList, setCatList] = useState([]);
  const [subCatList, setSubCatList] = useState([]);
  const [subSubCatList, setSubSubCatList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [grapeList, setGrapeList] = useState([]);
  const [sorting, setSorting] = useState(null);

  const [filterObj, setFilterObj] = useState({
    category: [],
    subCategory: [],
    subsubcategory: [],
    country: [],
    grape: [],
  });
  const productListHandler = async () => {
    if (search) {
      let productRes = await fetchProductSearchist(search);
      setProductList(productRes.data);
    }
  };

  const handleSorting = () => {
    let sortingArr = [];

    if (sorting === "price-low-high" || sorting === "price-high-low") {
      sortingArr = [...productList].sort((a, b) => {
        //sort by salerate asc
        const salerateA = a.itdetails[0].salerate / a.itdetails[0].ml;
        const salerateB = b.itdetails[0].salerate / b.itdetails[0].ml;
        if (sorting === "price-low-high") {
          if (salerateA < salerateB) return -1;
          if (salerateA > salerateB) return 1;
          return 0;
        } else {
          if (salerateA > salerateB) return -1;
          if (salerateA < salerateB) return 1;
          return 0;
        }
      });
    }

    if (sorting === "name-z-a" || sorting === "name-a-z") {
      if (sorting === "name-z-a") {
        sortingArr = [...productList].sort((a, b) => {
          if (a.item.toLowerCase() < b.item.toLowerCase()) return 1;
          if (a.item.toLowerCase() > b.item.toLowerCase()) return -1;
        });
      } else {
        sortingArr = [...productList].sort((a, b) => {
          if (a.item.toLowerCase() > b.item.toLowerCase()) return 1;
          if (a.item.toLowerCase() < b.item.toLowerCase()) return -1;
        });
      }
    }

    setProductList([...sortingArr]);
  };

  useEffect(() => {
    productList.length > 0 && sorting !== "Sort by" && handleSorting();
  }, [sorting]);

  const categoryList = async () => {
    let categoryRes = await fetchCategory();
    setCatList(categoryRes.data);
  };

  const subCategoryList = async () => {
    let categoryRes = await fetchHomeSubCategory({
      categories: JSON.stringify(filterObj.category),
    });

    setSubCatList(categoryRes.data);
  };

  const subSubCategoryList = async () => {
    let categoryRes = await fetchHomeSubSubCategory({
      categories: JSON.stringify(filterObj.category),
      subcategories: JSON.stringify(filterObj.subCategory),
    });
    setSubSubCatList(categoryRes.data);
  };
  const handleCountry = async () => {
    let categoryRes = await fetchCountry({
      categories: JSON.stringify(filterObj.category),
      subcategories: JSON.stringify(filterObj.subCategory),
      subsubcategories: JSON.stringify(filterObj.subsubcategory),
    });
    setCountryList(categoryRes.data);
  };
  const handleGrape = async () => {
    let categoryRes = await fetchGrape({
      categories: JSON.stringify(filterObj.category),
      subcategories: JSON.stringify(filterObj.subCategory),
      subsubcategories: JSON.stringify(filterObj.subsubcategory),
      country: JSON.stringify(filterObj.country),
    });
    setGrapeList(categoryRes.data);
  };

  useEffect(() => {
    if (filterObj.category.length) {
      subCategoryList();
    } else {
      setSubSubCatList([]);
      setSubCatList([]);
    }
  }, [filterObj.category]);
  useEffect(() => {
    if (filterObj.subCategory.length) {
      subSubCategoryList();
    } else {
      setSubSubCatList([]);
    }
  }, [filterObj.subCategory]);
  useEffect(() => {
    if (
      filterObj.category &&
      filterObj.subCategory.length &&
      filterObj.subsubcategory.length
    ) {
      handleCountry();
    }
  }, [filterObj.category, filterObj.subCategory, filterObj.subsubcategory]);
  useEffect(() => {
    if (
      filterObj.category &&
      filterObj.subCategory.length &&
      filterObj.subsubcategory.length &&
      filterObj.country.length
    ) {
      handleGrape();
    }
  }, [
    filterObj.category,
    filterObj.subCategory,
    filterObj.subsubcategory,
    filterObj.country,
  ]);

  useEffect(() => {
    if (search) {
      productListHandler();
    } else {
      categoryList();
      setProductList([]);
    }
  }, [search]);
  const handleCateFilter = (event, result) => {
    var updatedList = [...filterObj.category];
    if (event.target.checked) {
      updatedList = [...filterObj.category, event.target.value];
    } else {
      updatedList.splice(filterObj.category.indexOf(event.target.value), 1);
    }
    setFilterObj({ ...filterObj, category: updatedList });
  };
  const handleSubCateFilter = (event, result) => {
    var updatedList = [...filterObj.subCategory];
    if (event.target.checked) {
      updatedList = [...filterObj.subCategory, event.target.value];
    } else {
      updatedList.splice(filterObj.subCategory.indexOf(event.target.value), 1);
    }
    setFilterObj({ ...filterObj, subCategory: updatedList });
  };
  const handleSubSubCateFilter = (event, result) => {
    var updatedList = [...filterObj.subsubcategory];
    if (event.target.checked) {
      updatedList = [...filterObj.subsubcategory, event.target.value];
    } else {
      updatedList.splice(
        filterObj.subsubcategory.indexOf(event.target.value),
        1
      );
    }
    setFilterObj({ ...filterObj, subsubcategory: updatedList });
  };
  const handleCountryFilter = (event, result) => {
    var updatedList = [...filterObj.country];
    if (event.target.checked) {
      updatedList = [...filterObj.country, event.target.value];
    } else {
      updatedList.splice(filterObj.country.indexOf(event.target.value), 1);
    }
    setFilterObj({ ...filterObj, country: updatedList });
  };
  const handleGrapeRes = (event, result) => {
    var updatedList = [...filterObj.grape];
    if (event.target.checked) {
      updatedList = [...filterObj.grape, event.target.value];
    } else {
      updatedList.splice(filterObj.grape.indexOf(event.target.value), 1);
    }
    setFilterObj({ ...filterObj, grape: updatedList });
  };
  const handleFilter = async () => {
    let categoryRes = await fetchFilterData({
      categories: JSON.stringify(filterObj.category),
      subcategories: JSON.stringify(filterObj.subCategory),
      subsubcategories: JSON.stringify(filterObj.subsubcategory),
      country: JSON.stringify(filterObj.country),
      grape: JSON.stringify(filterObj.grape),
    });

    setProductList(categoryRes.data);
  };
  return (
    <>
      <div id="body_wrapper">
        <div class="pt-3">
          <div class="row g-0 mx-0 mx-lg-3 pb-4">
            <div class="col-12 col-lg-3 col-md-3 px-3" id="filtercolumn">
              <div class="modal-header pb-4 pt-3 desktop-mobile-view">
                <h5 class="modal-title">Filter By</h5>
                <button
                  type="button"
                  class="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <section class="wbr-product-listing-filters w-100 ll-sticky-banner">
                <div class="accordion" id="accordionFlushExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button
                        class="accordion-button acr-btn fs-20 fw-500 pt-1 pb-1"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-6"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        Category
                      </button>
                    </h2>
                    <div id="flush-6" class="accordion-collapse collapse show">
                      <div class="accordion-body">
                        {catList.map((result, index) => {
                          return (
                            <div class="form-check">
                              <input
                                class="form-check-input wbr-filter-input"
                                type="checkbox"
                                id="term-671"
                                value={result.category}
                                data-tax="attribute-6"
                                data-term={result.category}
                                data-arrtibute-collection-id={result.category}
                                data-query="attribute-6=6"
                                onChange={(e, result) =>
                                  handleCateFilter(e, result)
                                }
                              />
                              <label class="form-check-label" for="term-671">
                                {result.category}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button
                        class="accordion-button acr-btn fs-20 fw-500 pt-1 pb-1"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-5"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        Sub-cat
                      </button>
                    </h2>
                    <div id="flush-5" class="accordion-collapse collapse show">
                      <div class="accordion-body">
                        {subCatList.map((result) => {
                          return (
                            <div class="form-check">
                              <input
                                class="form-check-input wbr-filter-input"
                                type="checkbox"
                                value={result.subcategory}
                                data-tax="attribute-5"
                                data-term={result.subcategory}
                                data-arrtibute-collection-id={
                                  result.subcategory
                                }
                                data-query="attribute-5=5"
                                onChange={(e, result) =>
                                  handleSubCateFilter(e, result)
                                }
                              />
                              <label class="form-check-label" for="term-114">
                                {result.subcategory}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button
                        class="accordion-button acr-btn fs-20 fw-500 pt-1 pb-1"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-9"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        Sub-sub-cat
                      </button>
                    </h2>
                    <div id="flush-9" class="accordion-collapse collapse show">
                      <div class="accordion-body">
                        {subSubCatList.map((result) => {
                          return (
                            <div class="form-check">
                              <input
                                class="form-check-input wbr-filter-input"
                                type="checkbox"
                                id="term-482"
                                value={result.subsubcategory}
                                data-tax="attribute-9"
                                data-term="482"
                                data-arrtibute-collection-id="482"
                                data-query="attribute-9=9"
                                onChange={(e, result) =>
                                  handleSubSubCateFilter(e, result)
                                }
                              />
                              <label class="form-check-label" for="term-482">
                                {result.subsubcategory}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button
                        class="accordion-button acr-btn fs-20 fw-500 pt-1 pb-1"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-10"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        Country
                      </button>
                    </h2>
                    <div id="flush-10" class="accordion-collapse collapse show">
                      <div class="accordion-body">
                        {countryList.map((result) => {
                          return (
                            <div class="form-check">
                              <input
                                class="form-check-input wbr-filter-input"
                                type="checkbox"
                                id="term-482"
                                value={result.country}
                                data-tax="attribute-9"
                                data-term={result.country}
                                data-arrtibute-collection-id={result.country}
                                data-query="attribute-9=9"
                                onChange={(e, result) =>
                                  handleCountryFilter(e, result)
                                }
                              />
                              <label class="form-check-label" for="term-482">
                                {result.country}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button
                        class="accordion-button acr-btn fs-20 fw-500 pt-1 pb-1"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-11"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        Grape
                      </button>
                    </h2>
                    <div id="flush-11" class="accordion-collapse collapse show">
                      <div class="accordion-body">
                        {grapeList.map((result) => {
                          {
                            return (
                              <div class="form-check">
                                <input
                                  class="form-check-input wbr-filter-input"
                                  type="checkbox"
                                  id="term-482"
                                  value={result.grape}
                                  data-tax="attribute-9"
                                  data-term={result.grape}
                                  data-arrtibute-collection-id="482"
                                  data-query="attribute-9=9"
                                  onChange={(e, result) =>
                                    handleGrapeRes(e, result)
                                  }
                                />
                                <label class="form-check-label" for="term-482">
                                  {result.grape}
                                </label>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row m-0 g-0 py-3">
                  <div class="col me-3">
                    <button
                      type="button"
                      class="btn btn-light rounded-pill text-dark w-100 py-2 wbr-reset-filter"
                      onClick={() => {
                        setCatList([]);
                        setSubCatList([]);
                        setSubSubCatList([]);
                        setCountryList([]);
                        setGrapeList([]);
                        categoryList();
                      }}
                    >
                      RESET
                    </button>
                  </div>
                  <div class="col">
                    <button
                      type="button"
                      data-url="#"
                      class="btn btn-dark rounded-pill bg-alpha w-100 wbr-fliter-apply py-2"
                      onClick={() => handleFilter()}
                    >
                      APPLY
                    </button>
                  </div>
                </div>
              </section>
            </div>
            <div class="col-md-9 col-lg-9 col-12">
              <section>
                <div
                  class="row align-items-center m-0 bg-white py-2 px-2 rounded-20 card-short"
                  id="ShortColumn"
                >
                  <div class="col-12 col-md-4 d-none d-lg-block">
                    <div class="input-group align-self-center mb-3 mb-md-0 d-none">
                      <input
                        type="search"
                        class="form-control"
                        placeholder="Search here"
                      />
                      <button
                        class="btn btn-search bg-secondary text-white"
                        type="button"
                      >
                        <i class="bi bi-search"></i>
                      </button>
                    </div>
                  </div>
                  <div class="modal-header pb-4 pt-3 desktop-mobile-view">
                    <h5 class="modal-title">Filter By</h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="col-12 col-md-5 text-lg-end text-dark fs-12 fs-md-14 fw-400">
                    Showing 25 products
                  </div>

                  <div class="col-13 col-md-3 text-end d-lg-block pt-3">
                    <select
                      class="form-select bg-light-gray"
                      id="ddlProductSortyBy"
                      name="ddlProductSortyBy"
                      onClick={(e) => setSorting(e.target.value)}
                    >
                      <option selected="">Sort by</option>
                      <option value="price-low-high">Price Low to High</option>
                      <option value="price-high-low">Price High to Low</option>
                      <option value="name-a-z">Name A - Z</option>
                      <option value="name-z-a">Name Z - A</option>
                    </select>
                  </div>
                </div>
                <div class="shopProd_mn shopAll_mn">
                  <div class="container">
                    <div class="shopProd_title">
                      <h2>Featured Wines</h2>
                    </div>
                    <div class="shopProd_list animatedParent animateOnce">
                      {/* <Slider {...settingss}> */}

                      {productList.map((result) => {
                        return (
                          <div class="shopProd_item animated">
                            <div class="box">
                              <div class="top_part">
                                <div class="img_wrap">
                                  <img src={Vector} alt="Red Wine" />
                                </div>
                                <div class="detail">
                                  <div class="category">
                                    <span class="main">
                                      {result.subcategory}
                                    </span>{" "}
                                    /{" "}
                                    <span class="sub">
                                      {result.subsubcategory}
                                    </span>
                                  </div>
                                  <div class="title">
                                    <h3>{result.item}</h3>
                                  </div>
                                </div>
                              </div>
                              <div class="bottom_part">
                                <div class="list">
                                  <ul>
                                    {result.itdetails.map((itm) => {
                                      return (
                                        <li>
                                          <span class="winQty">{itm.ml}ML</span>
                                          <span class="winPrc">
                                            {itm.salerate !== itm.rate && (
                                              <small>₹{itm.rate}</small>
                                            )}
                                            ₹{itm.salerate}
                                          </span>
                                          <span style={{ color: "red" }}>
                                            {(itm.qty <= 0 || !itm.qty) && (
                                              <small>not in stock</small>
                                            )}
                                          </span>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {/* </Slider> */}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div class="filter">
        <a id="Shor1" class="btn btn-light">
          <i class="bi bi-sort-alpha-up"></i>&nbsp;Short
        </a>
        <a id="filter1" class="btn btn-light">
          <i class="bi bi-funnel"></i>&nbsp;Filter
        </a>
      </div>
    </>
  );
};
export default Product;
